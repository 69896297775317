<template>
  <div>
    <general-table
      :api-url="apiUrl"
      :add-component-name="addComponentName"
      :edit-component="editComponent"
      :delete-content="true"
      :block-content="false"
      :edit-content="true"
      :view-content="false"
      :add-type="addType"
      :columns="columns"
      :type="type"
      :selectable="false"
    >
      <template #cell(certificate)="data">
        <div
          v-if="data.item.certificate"
          style="width: 100%; height: 100%;"
        >
          <a
            target="_blank"
            :href="data.item.certificate.url"
          >
            <img
              src="@/assets/images/icons/certificate.svg"
              style="height: 4rem; width: 4rem"
            >
            <span class="ml-1 toggle-content">{{ data.item.certificate.name }}</span>
          </a>
        </div>
      </template>

    </general-table>
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'user-training',
      addType: 'Add User Training',
      addComponentName: 'add-user-training',
      editComponent: 'edit-user-training',
      viewContent: true,
      type: 'page',
      partners: [],
      filter: {},
      types: '',
      columns: [
        { key: 'id', sortable: true },
        { key: 'start_date', label: 'Start Date' },
        { key: 'end_date', label: 'End Date' },
        { key: 'score', label: 'Score' },
        { key: 'expiration_date', label: 'Expiration Date' },
        { key: 'users.name', label: 'Engineer' },
        { key: 'trainings.name', label: 'Training' },
        { key: 'certificate', label: 'Certificate' },

        { key: 'actions' },
      ],
    }
  },

  computed: {

    apiUrl() {
      let base = 'user-training?'

      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.filter)) {
        if (key && value) {
          base += `${key}=${value}&`
        }
      }
      // console.log(base.slice(0, -1))
      return base.slice(0, -1)
    },
  },
  mounted() {
  },

  methods: {

    filterTypes(type) {
      const filteredType = this.status.filter(item => item.text !== type)
      this.types = filteredType
    },

  },
}
</script>

  <style></style>
